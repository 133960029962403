@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "~highlight.js/styles/monokai-sublime.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    ul,
    ol {
        list-style: revert;
        margin: revert;
        padding: revert;
    }
}

@layer utilities {
    /* Hide scrollbar for Chrome, Safari, and Opera */
    .hidden-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge, and Firefox */
    .hidden-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}

code {
    white-space: pre-wrap;
    overflow: hidden;
}

pre {
    display: flex;
    flex-direction: column;
    white-space: pre-wrap;
    overflow: hidden;
}

a {
    color: #00ff8f;
}

a:hover {
    color: #79f7be;
}

#changelogs .active svg {
    transform: rotate(180deg);
}

#changelogs .changelog-content {
    max-height: 0;
    opacity: 0;
    transition: max-height 0.5s linear, opacity 0.5s linear;
    overflow: hidden;
}
#changelogs .changelog-trigger.active + .changelog-content {
    max-height: 1000px;
    opacity: 1;
}
